import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"
import Page from "../../common/Page";

const openApiUrl = process.env.REACT_APP_OPENAPI_URL || "/openapi.yml";

const OpenApiView: React.FC = () => {
  return (
    <Page>
      <SwaggerUI url={openApiUrl}/>
    </Page>
  );
}

export default OpenApiView;