/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Page from '../../common/Page';
import Card from '../../common/Card';

const FrontPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <Card 
        title={t("app.components.FrontPage.heading")}
        styles={css({
          paddingTop: tokens.spacing.xxs,
          paddingBottom: tokens.spacing.xxs,
          '@media (min-width: 992px)': {
            paddingTop: tokens.spacing.xs,
            paddingBottom: tokens.spacing.xs,
          }
        })}
      >
        <Link to={"/openapi"}>
          {t("app.components.FrontPage.openapiLinkText")}
        </Link>
      </Card>
    </Page>
  );
}

export default FrontPage;